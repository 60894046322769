import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Join from './Join';
import Exec_Board from './Exec_Board';
import Login from './Login';
import Dashboard from './Dashboard';
import MainLayout from './MainLayout';

function App() {
    return (
        <Router>
            <MainLayout>
                <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/join" element={<Join />} />
                    <Route path="/executive-board" element={<Exec_Board />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                </Routes>
            </MainLayout>
        </Router>
    );
}

export default App;


