import React, { useRef, useEffect } from 'react';
import './Join.css';
import joinImage from './images/join/join-image1.jpg'; // Background image
import joinImage2 from './images/join/join-events-image.jpeg';
import eberry from './images/headshots/elizabeth_berry.jpeg';

const Join = () => {
    const backgroundImageRef = useRef(null);

    const handleScrollDown = () => {
        const joinContentSection = document.querySelector('.join-content-section');
        if (joinContentSection) {
            joinContentSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="join-page">
            <div className="join-section" style={{ backgroundImage: `url(${joinImage})` }} ref={backgroundImageRef}>
                <div className="join-text">
                    <h1>JOIN US</h1>
                    <p>Become a part of our community.</p>
                </div>
                <div className="scroll-arrow" onClick={handleScrollDown}>
                    <span className="arrow"></span>
                    <span className="scroll-text">SCROLL DOWN</span>
                </div>
            </div>
            <div className="join-content-section">
                <div className="recruitment-season">
                    <h2>FALL 2024 RECRUITMENT</h2>
                    <h1>_____</h1>
                    <a href="https://forms.gle/MqnKnqxKPqKAtSRHA" target="_blank" className="recruitment-button">APPLY NOW</a>
                </div>
                <img src={joinImage2} alt="Events" className="events-image"/> 
                <div className="event-box-container">
                    <a href="https://maps.osu.edu/?utm_source=CampusMapRedirect&utm_medium=website&utm_campaign=0274#data_s=id%3AdataSource_8-1916b96cc76-layer-20-1%3A539" target="_blank" className="event-box">
                        <h3>INFO SESSION 1</h3>
                        <p>September 3rd 7pm-9pm</p>
                        <p>Independence Hall #100</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_source=CampusMapRedirect&utm_medium=website&utm_campaign=0274#data_s=id%3AdataSource_8-1916b96cc76-layer-20-1%3A539" target="_blank" className="event-box">
                        <h3>INFO SESSION 2</h3>
                        <p>September 4th 7pm-9pm</p>
                        <p>Independence Hall #100</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_source=CampusMapRedirect&utm_medium=website&utm_campaign=0274#data_s=id%3AdataSource_8-1916b96cc76-layer-20-1%3A627" target="_blank" className="event-box">
                        <h3>MEMBER MINGLE</h3>
                        <p>September 5th 8pm-10pm</p>
                        <p>Hitchcock Hall #035</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_source=CampusMapRedirect&utm_medium=website&utm_campaign=0274#data_s=id%3AdataSource_8-1916b96cc76-layer-20-1%3A15343" target="_blank" className="event-box">
                        <h3>*PROFESSIONAL EVENT</h3>
                        <p>September 8th 2pm-4pm</p>
                        <p>Curl Viewpoint</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_source=CampusMapRedirect&utm_medium=website&utm_campaign=0274#data_s=id%3AdataSource_8-1916b96cc76-layer-20-1%3A506" target="_blank" className="event-box">
                        <h3>*SERVICE EVENT</h3>
                        <p>September 9th 7:30pm-9:30pm</p>
                        <p>Ohio Staters Traditions Room</p>
                    </a>
                    <a href="https://maps.osu.edu/?utm_source=CampusMapRedirect&utm_medium=website&utm_campaign=0274#data_s=id%3AdataSource_8-1916b96cc76-layer-20-1%3A506" target="_blank" className="event-box">
                        <h3>*INTERVIEWS</h3>
                        <p>September 9th - September 13th</p>
                        <p>Ohio Union KBK Rooms</p>
                    </a>
                </div>
                <div className="asterisk">
                    <h2>*Invite Only Events</h2>
                </div>
                <div className="questions-comments">
                    <div className="separator"></div>
                    <h2>QUESTIONS, COMMENTS, CONCERNS?</h2>
                    <p>Please reach out to our Logistical Recruitment Director for assistance:</p>
                    <a href="https://www.linkedin.com/in/elizabeth-berry-9a6a59260/" target="_blank" rel="noopener noreferrer">
                        <img src={eberry} alt="Elizabeth Berry" className="director-image"/>
                    </a>
                    <p className="director-name">Elizabeth Berry</p>
                    <p className="director-email">berry.906</p>
                </div>

            </div>
        </div>
    );
};

export default Join;
