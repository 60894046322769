import React, { useEffect, useRef } from 'react';
import './About.css'; // Import the CSS file for styling
import aboutImage from './images/about/about-image1.jpg'; // Import the image
import aboutImage3 from './images/about/about-image2.jpeg';
import aboutImage4 from './images/about/about-image3.jpg'; // Import the new image

import summitFinancialLogo from './images/about/about-sponsor-logos/summitfin.png';
import vicfinLogo from './images/about/about-sponsor-logos/vicfin.png';
import parkerhannifinLogo from './images/about/about-sponsor-logos/parkerhannifin.png';
import colonyLogo from './images/about/about-sponsor-logos/colony_hardware.png';
import sherwinLogo from './images/about/about-sponsor-logos/sherwin_williams.png';
import bishopWealth from './images/about/about-sponsor-logos/bishop-wealth.png';
import crossroadsGroups from './images/about/about-sponsor-logos/crossroads-groups.png';
import psc from './images/about/about-sponsor-logos/psc.png';
import tti from './images/about/about-sponsor-logos/tti.png';

import deloitteLogo from './images/about/about-partner-logos/deloitte.png';
import bofaLogo from './images/about/about-partner-logos/bofa.png';
import arrivelogisticsLogo from './images/about/about-partner-logos/arrivelogistics.png';

const About = () => {
    const memberRefs = useRef([]);
    const sponsorRefs = useRef([]);
    const partnerRefs = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in');
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        memberRefs.current.forEach(member => {
            if (member) {
                observer.observe(member);
            }
        });

        sponsorRefs.current.forEach(sponsor => {
            if (sponsor) {
                observer.observe(sponsor);
            }
        });

        partnerRefs.current.forEach(partner => {
            if (partner) {
                observer.observe(partner);
            }
        });

        return () => {
            memberRefs.current.forEach(member => {
                if (member) {
                    observer.unobserve(member);
                }
            });
            sponsorRefs.current.forEach(sponsor => {
                if (sponsor) {
                    observer.unobserve(sponsor);
                }
            });
            partnerRefs.current.forEach(partner => {
                if (partner) {
                    observer.unobserve(partner);
                }
            });
        };
    }, []);

    const handleScrollDown = () => {
        const aboutUsSection = document.querySelector('.about-us-section');
        if (aboutUsSection) {
            aboutUsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="about-container">
            <div className="about-section" style={{ backgroundImage: `url(${aboutImage})` }}>
                <div className="about-text">
                    <h1>WHO WE ARE</h1>
                    <p>Learn about the mission, vision, and history of BPAD.</p>
                </div>
                <div className="scroll-arrow" onClick={handleScrollDown}>
                    <span className="arrow"></span>
                    <span className="scroll-text">SCROLL DOWN</span>
                </div>
            </div>
            <div className="about-us-section">
                <h2>ABOUT US</h2>
                <p>
                    Buckeye Professional Advancement and Development (BPAD) is an organization that was founded to bring a holistic lens of professionalism to all majors at Ohio State. Our organization is guided by our five pillars of service, inclusion, unity, curiosity, and leadership. We strive to build a unified community of inclusive and caring individuals dedicated to building relationships.
                </p>
                <p> Our Mission is to develop Ohio State students across all disciplines into the upstanding leaders of tomorrow. To be recognized as a respected and advanced organization at The Ohio State University that empowers a community of ambitious, curious, servant leaders valuing both professional and personal development while creating relationships that last for life.</p>
                <div className="statistics">
                    <div className="stat">
                        <h3>107</h3>
                        <p>MEMBERS</p>
                    </div>
                    <div className="stat">
                        <h3>33</h3>
                        <p>MAJORS</p>
                    </div>
                    <div className="stat">
                        <h3>31</h3>
                        <p>MINORS</p>
                    </div>
                    <div className="stat">
                        <h3>3.7</h3>
                        <p>AVERAGE GPA</p>
                    </div>
                </div>
            </div>
            <div className="about-section third-image" style={{ backgroundImage: `url(${aboutImage3})` }}></div>
            <div className="members-section">
                <h2>OUR MEMBERS ARE</h2>
                <ul>
                    <li ref={el => memberRefs.current[0] = el}>Stamps Eminence Scholars</li>
                    <li ref={el => memberRefs.current[1] = el}>Morrill Scholars</li>
                    <li ref={el => memberRefs.current[2] = el}>Pace Setters</li>
                    <li ref={el => memberRefs.current[3] = el}>Honors Cohort</li>
                    <li ref={el => memberRefs.current[4] = el}>Buckeye Leadership Fellows</li>
                    <li ref={el => memberRefs.current[5] = el}>Fisher Futures</li>
                </ul>
            </div>
            <div className="about-section fourth-image" style={{ backgroundImage: `url(${aboutImage4})` }}></div>
            <div className="sponsors-partners-section">
                <h2>OUR SPONSORS</h2>
                <div className="sponsors-logos">
                    <img src={summitFinancialLogo} alt="Summit Financial" className="sponsors-partners-logo custom-size" ref={el => sponsorRefs.current[2] = el}/>
                    <img src={vicfinLogo} alt="VicFin" className="sponsors-partners-logo" ref={el => sponsorRefs.current[3] = el}/>
                    <img src={parkerhannifinLogo} alt="Parker Hannifin" className="sponsors-partners-logo" ref={el => sponsorRefs.current[4] = el}/>
                    <img src={colonyLogo} alt="Colony Hardware" className="sponsors-partners-logo" ref={el => sponsorRefs.current[5] = el}/>
                    <img src={sherwinLogo} alt="Sherwin-Williams" className="sponsors-partners-logo" ref={el => sponsorRefs.current[6] = el}/>
                    <img src={bishopWealth} alt="Bishop Wealth" className="sponsors-partners-logo" ref={el => sponsorRefs.current[7] = el}/>
                    <img src={crossroadsGroups} alt="Crossroads Groups" className="sponsors-partners-logo" ref={el => sponsorRefs.current[8] = el}/>
                    <img src={psc} alt="PSC Crane and Rigging" className="sponsors-partners-logo" ref={el => sponsorRefs.current[9] = el}/>
                    <img src={tti} alt="Techtronic Industries" className="sponsors-partners-logo" ref={el => sponsorRefs.current[10] = el}/>
                </div>
                <h2>OUR PARTNERS</h2>
                <div className="partners-logos">
                    <img src={deloitteLogo} alt="Deloitte" className="sponsors-partners-logo" ref={el => partnerRefs.current[0] = el}/>
                    <img src={bofaLogo} alt="Bank of America" className="sponsors-partners-logo" ref={el => partnerRefs.current[1] = el}/>
                    <img src={arrivelogisticsLogo} alt="Arrive Logistics" className="sponsors-partners-logo" ref={el => partnerRefs.current[2] = el}/>
                </div>
            </div>
        </div>
    );
};

export default About;
