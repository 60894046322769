import React, { useEffect, useRef } from 'react';
import './Exec_Board.css';
import membersImage from './images/exec-board/exec-board-image1.jpg';

// Sample data for executive board members
const executiveBoard = [
    {
        name: "Abby Muglia",
        subtitle: "President",
        email: "muglia.3",
        image: require("./images/headshots/abby_muglia.jpeg")
    },
    {
        name: "Anna Hawkins",
        subtitle: "VP of Operations",
        email: "hawkins.777",
        image: require("./images/headshots/anna_hawkins.jpg")
    },
    {
        name: "Devon Sever",
        subtitle: "VP of Finance",
        email: "sever.41",
        image: require("./images/headshots/devon_sever.jpg")
    },
    {
        name: "Colin Joyce",
        subtitle: "VP of Marketing",
        email: "joyce.284",
        image: require("./images/headshots/colin_joyce.jpg")
    },
    {
        name: "Audrey Glaser",
        subtitle: "VP of Membership",
        email: "glaser.153",
        image: require("./images/headshots/audrey_glaser.jpeg")
    },
    {
        name: "John Sukernek",
        subtitle: "VP of Alumni Relations",
        email: "sukernek.1",
        image: require("./images/headshots/john_sukernek.jpg")
    },
    {
        name: "Leah Salomone",
        subtitle: "VP of Professional Relations",
        email: "salomone.18",
        image: require("./images/headshots/leah_salomone.jpg")
    },
    {
        name: "Tyler Ringwald",
        subtitle: "VP of Development",
        email: "ringwald.15",
        image: require("./images/headshots/tyler_ringwald.jpg")
    },
    {
        name: "Natalie Rash",
        subtitle: "VP of Administration",
        email: "rash.32",
        image: require("./images/headshots/natalie_rash.jpg")
    }
];

/*
const membership = [
    {
        name: "Brynn Hillard",
        subtitle: "Computer Science and Engineering",
        image: require("./components/headshots/brynn_hillard.jpeg")
    },
    {
        name: "Bella London",
        subtitle: "Computer Science and Engineering",
        image: require("./components/headshots/bella_london.jpeg")
    },
    {
        name: "Elizabeth Berry",
        subtitle: "Neuroscience",
        image: require("./components/headshots/elizabeth_berry.jpeg")
    },
    {
        name: "Ellie Egan",
        subtitle: "Marketing",
        image: require("./components/headshots/ellie_egan.jpeg")
    },
    {
        name: "Jackson Schoolcraft",
        subtitle: "Finance",
        image: require("./components/headshots/jackson_schoolcraft.jpeg")
    },
    {
        name: "Jayne Dimitri",
        subtitle: "Public Management, Leadership, and Policy",
        image: require("./components/headshots/jayne_dimitri.jpeg")
    },
    {
        name: "Noah Curbelo",
        subtitle: "Business Administration",
        image: require("./components/headshots/noah_curbelo.jpeg")
    },
    {
        name: "Ava Estep",
        subtitle: "Finance",
        image: require("./components/headshots/ava_estep.jpeg")
    },
    {
        name: "Camden Allen",
        subtitle: "Mechanical Engineering",
        image: require("./components/headshots/camden_allen.jpeg")
    },
    {
        name: "Charlie Asplin",
        subtitle: "International Business",
        image: require("./components/headshots/charlie_asplin.jpeg")
    },
    {
        name: "Connor Pletikapich",
        subtitle: "Information Systems",
        image: require("./components/headshots/connor_pletikapich.jpeg")
    },
    {
        name: "Dan Stedronsky",
        subtitle: "Computer Science and Engineering",
        image: require("./components/headshots/dan_stedronsky.jpeg")
    },
    {
        name: "Grace Terranova",
        subtitle: "TBD",
        image: require("./components/headshots/grace_terranova.jpeg")
    },
    {
        name: "Hala Ahmed",
        subtitle: "Finance",
        image: require("./components/headshots/hala_ahmed.jpeg")
    },
    {
        name: "Madison Blake",
        subtitle: "Economics",
        image: require("./components/headshots/madison_blake.jpg")
    },
    {
        name: "Sophie Lee",
        subtitle: "Marketing",
        image: require("./components/headshots/sophie_lee.jpeg")
    },
    {
        name: "Adam Duvall",
        subtitle: "Finance",
        image: require("./components/headshots/adam_duvall.jpeg")
    },
    {
        name: "Anna Kuntz",
        subtitle: "Finance",
        image: require("./components/headshots/anna_kuntz.jpeg")
    },
    {
        name: "Audrey Glaser",
        subtitle: "Health Sciences",
        image: require("./components/headshots/audrey_glaser.jpeg")
    },
    {
        name: "Brenden Karchefsky",
        subtitle: "Political Science",
        image: require("./components/headshots/brenden_karchefsky.jpeg")
    },
    {
        name: "Brooklynn Laukhuf",
        subtitle: "Health Sciences",
        image: require("./components/headshots/brooklynn_laukhuf.jpeg")
    },
    {
        name: "Camille Gregory",
        subtitle: "Communications",
        image: require("./components/headshots/camille_gregory.jpeg")
    },
    {
        name: "Colette Beans",
        subtitle: "Biology",
        image: require("./components/headshots/colette_beans.jpeg")
    },
    {
        name: "Colleen Reagan",
        subtitle: "Strategic Communications",
        image: require("./components/headshots/colleen_reagan.jpeg")
    },
    {
        name: "Collin Stegemiller",
        subtitle: "Business Administration",
        image: require("./components/headshots/collin_stegemiller.jpeg")
    },
    {
        name: "Galen Cline",
        subtitle: "Radiologic Sciences and Therapy",
        image: require("./components/headshots/galen_cline.jpeg")
    },
    {
        name: "Heath Younkin",
        subtitle: "Electrical and Computer Engineering",
        image: require("./components/headshots/heath_younkin.jpeg")
    },
    {
        name: "Jackie McKenna",
        subtitle: "Molecular Genetics",
        image: require("./components/headshots/jackie_mckenna.jpeg")
    },
    {
        name: "Jenna Smith",
        subtitle: "Psychology & Criminology",
        image: require("./components/headshots/jenna_smith.jpeg")
    },
    {
        name: "Kareena Jain",
        subtitle: "Neuroscience",
        image: require("./components/headshots/kareena_jain.jpeg")
    },
    {
        name: "Macy Sweester",
        subtitle: "Strategic Communications",
        image: require("./components/headshots/macy_sweetser.jpeg")
    },
    {
        name: "Matthew Morgan",
        subtitle: "Neuroscience",
        image: require("./components/headshots/matthew_morgan.jpeg")
    },
    {
        name: "Meba Wondwossen",
        subtitle: "Molecular Genetics",
        image: require("./components/headshots/meba_wondwossen.jpeg")
    },
    {
        name: "Mia Slogar",
        subtitle: "Primary Education",
        image: require("./components/headshots/mia_slogar.jpeg")
    },
    {
        name: "Nick Uhl",
        subtitle: "Finance",
        image: require("./components/headshots/nick_uhl.jpeg")
    },
    {
        name: "Owen Damon",
        subtitle: "Material Science and Engineering",
        image: require("./components/headshots/owen_damon.jpeg")
    },
    {
        name: "Valeria Favila",
        subtitle: "Marketing",
        image: require("./components/headshots/valeria_favila.jpeg")
    },
    {
        name: "Vince Koprowski",
        subtitle: "Chemical Engineering",
        image: require("./components/headshots/vince_koprowski.jpeg")
    },
    {
        name: "Annie McGinnis",
        subtitle: "Sport Industry",
        image: require("./components/headshots/annie_mcginnis.jpeg")
    },
    {
        name: "Clark Nemer",
        subtitle: "Finance",
        image: require("./components/headshots/clark_nemer.jpeg")
    },
    {
        name: "Colson Bunch",
        subtitle: "Data Analytics",
        image: require("./components/headshots/colson_bunch.jpeg")
    },
    {
        name: "Daniel Petrowsky",
        subtitle: "Biochemistry",
        image: require("./components/headshots/daniel_petrowsky.jpeg")
    },
    {
        name: "Erin Birch",
        subtitle: "Finance",
        image: require("./components/headshots/erin_birch.jpeg")
    },
    {
        name: "Jordan West",
        subtitle: "Finance",
        image: require("./components/headshots/jordan_west.jpeg")
    },
    {
        name: "Kevin Ren",
        subtitle: "Marketing",
        image: require("./components/headshots/kevin_ren.jpeg")
    },
    {
        name: "Lauren Sloan",
        subtitle: "Management Information Systems",
        image: require("./components/headshots/lauren_sloan.jpeg")
    },
    {
        name: "Luka Krejsa",
        subtitle: "Economics & Psychology",
        image: require("./components/headshots/luka_krejsa.jpeg")
    },
    {
        name: "Matthew Dueweke",
        subtitle: "Industrial and Systems Engineering",
        image: require("./components/headshots/matthew_dueweke.jpeg")
    },
    {
        name: "McKenzie Banks",
        subtitle: "Finance",
        image: require("./components/headshots/mckenzie_banks.jpeg")
    },
    {
        name: "Noah Zappone",
        subtitle: "Marketing",
        image: require("./components/headshots/noah_zappone.jpeg")
    },
    {
        name: "Olivia Nevin",
        subtitle: "Marketing",
        image: require("./components/headshots/olivia_nevin.jpeg")
    },
    {
        name: "PJ Bower",
        subtitle: "Neuroscience",
        image: require("./components/headshots/pj_bower.jpeg")
    },
    {
        name: "Ryan Kachmarik",
        subtitle: "Security and Intelligence",
        image: require("./components/headshots/ryan_kachmarik.jpeg")
    },
    {
        name: "Sanjit Rameshkumar",
        subtitle: "Computer Science and Engineering",
        image: require("./components/headshots/sanjit_rameshkumar.jpeg")
    },
    {
        name: "Sam Lee",
        subtitle: "Aerospace Engineering",
        image: require("./components/headshots/sam_lee.jpeg")
    },
    {
        name: "Yaa Darkwa",
        subtitle: "Neuroscience",
        image: require("./components/headshots/yaa_darkwa.jpeg")
    }
];
*/

const Members = () => {
    const memberRefs = useRef([]);

    useEffect(() => {
        memberRefs.current = memberRefs.current.slice(0, executiveBoard.length /*+ membership.length*/);

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in');
                        observer.unobserve(entry.target); // Stop observing once it has faded in
                    }
                });
            },
            {
                threshold: 0.1,
            }
        );

        memberRefs.current.forEach(member => {
            if (member) {
                observer.observe(member);
            }
        });

        return () => {
            memberRefs.current.forEach(member => {
                if (member) {
                    observer.unobserve(member);
                }
            });
        };
    }, []);

    const handleScrollDown = () => {
        const contentSection = document.querySelector('.members-page-content');
        if (contentSection) {
            contentSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <div className="members-page-section" style={{ backgroundImage: `url(${membersImage})` }}>
                <div className="members-page-text">
                    <h1>OUR EXECUTIVE BOARD</h1>
                    <p>Meet the leaders who drive our community forward.</p>
                </div>
                <div className="members-page-scroll-arrow" onClick={handleScrollDown}>
                    <span className="members-page-arrow"></span>
                    <span className="members-page-scroll-text">SCROLL DOWN</span>
                </div>
            </div>
            <div  className="members-page-background">
                <div className="members-page-content">
                    <div className="subsection">
                        <h2 className="subsection-header">EXECUTIVE BOARD</h2>
                        <div className="separator"></div>
                        <div className="members-list">
                            {executiveBoard.map((member, index) => (
                                <div className="member-card" key={index} ref={el => memberRefs.current[index] = el}>
                                    <img src={member.image} alt={member.name} />
                                    <div className="name">{member.name}</div>
                                    <div className="subtitle">{member.subtitle}</div>
                                    <div className="email">{member.email}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    
                    {/* <div className="subsection">
                        <h2 className="subsection-header">MEMBERSHIP</h2>
                        <div className="separator"></div>
                        <div className="members-list">
                            {membership.map((member, index) => (
                                <div className="member-card" key={index + executiveBoard.length} ref={el => memberRefs.current[index + executiveBoard.length] = el}>
                                    <img src={member.image} alt={member.name} />
                                    <div className="name">{member.name}</div>
                                    <div className="subtitle">{member.subtitle}</div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </div>
    );
};

export default Members;