import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
        <div class = "footerContainer">
            <div class = "socialIcons">
                <a href="https://www.instagram.com/bpadosu/"><i class="fa-brands fa-instagram"></i></a>
                <a href="https://www.linkedin.com/company/bpad/"><i class="fa-brands fa-linkedin"></i></a>
                <a href="https://www.facebook.com/p/BPAD-Ohio-State-University-100076825395182/"><i class="fa-brands fa-facebook"></i></a>
            </div>
            <div class = "footerBottom">
                <p>Buckeye Professional Advancement and Development © 2024</p>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
