import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from './images/bpad-logo.png';
import './Navbar.css';

const Navbar = () => {
    const location = useLocation();
    const isLoginPage = location.pathname === '/login';

    return (
        <nav className={`nav ${isLoginPage ? 'nav-login' : ''}`}>
            <Link to="/">
                <img src={logo} className="logo" alt="BPAD Logo" />
            </Link>
            <ul className="nav-links">
                <li><Link to="/">HOME</Link></li>
                <li><Link to="/about">ABOUT</Link></li>
                <li><Link to="/join">JOIN</Link></li>
                <li><Link to="/executive-board">EXECUTIVE BOARD</Link></li>
                <li><Link to="/login">LOGIN</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;

