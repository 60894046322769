import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import bpadIcon from './images/login/login-bpad-icon.png';

const Login = () => {
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [showError, setShowError] = useState(false); // State to control visibility

    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();
        if (password === 'ripCammy23!') {
            navigate('/dashboard');
        } else {
            setErrorMessage('Incorrect password, please try again.');
            setShowError(true); // Show the error message
        }
    };

    useEffect(() => {
        if (showError) {
            const timer = setTimeout(() => {
                setShowError(false); // Hide the error message after 3 seconds
            }, 2000);
            return () => clearTimeout(timer); // Cleanup the timer
        }
    }, [showError]);

    return (
        <div className="login-page">
            <div className="login-container">
                <img src={bpadIcon} alt="Logo" className="login-logo" />
                <h2>MEMBER LOGIN</h2>
                <form onSubmit={handleLogin}>
                    <input 
                        type="password" 
                        placeholder="Password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        className="login-input"
                    />
                    <button type="submit" className="login-button">→</button>
                </form>
                <div className="error-container">
                    {errorMessage && (
                        <p className={`error-message ${!showError ? 'fade-out' : ''}`}>
                            {errorMessage}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Login;
